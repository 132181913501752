<template>
  <v-container>
    <v-form @submit.prevent="InsertUpdate()">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-text-field
            label="ชื่อพนักงาน"
            placeholder="ชื่อพนักงาน"
            :rules="[rules.required(UserStatus)]"
            outlined
            dense
            v-model="User.UserName"
            @blur="Blur(User.UserName)"
            required
            ref="UserName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-text-field
            label="โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            outlined
            dense
            v-model="User.UserTel"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3">
           <v-text-field
            label="วงเงินยืม"
            placeholder="จำนวนเงิน"
            outlined
            dense
            type="number"
            v-model="User.UserMoneyLimt"
          ></v-text-field>
         
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-textarea
            label="หมายเหตุ"
            placeholder="หมายเหตุ"
            outlined
            dense
            v-model="User.UserRemark"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn block type="submit">
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn block @click="$router.go(-1)">
            กลับ
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Axios from "axios";

export default {
  data() {
    return {
      User: {
        UserId: "",
        UserName: "",
        UserTel: "",
        UserRemark: "",
        UserLevel: "E",
        UserMoneyLimt:0
      },
      UserStatus: true,
      UserName: "",
      rules: {
        required: value => !!value || "เป็นพนักงานแล้ว"
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.UserId != null) {
        this.User.UserId = this.$route.query.UserId;
        this.SelectId(this.User.UserId);
      }
    });
  },
  methods: {
    Blur(v) {
      if (!v) return;
      Axios.post("/api/v1/select/ValidateUserName/User", { UserName: v }).then(res => {
        if (res.status == 200) {
          if (res.data.UserStatus) {
            if (this.UserName != v && this.$route.query.UserId != null) {
              this.UserStatus = false;
              this.User.UserName = "";
              this.$refs.UserName.focus();
            } else if (this.$route.query.UserId == null) {
              this.UserStatus = false;
              this.User.UserName = "";
              this.$refs.UserName.focus();
            } else {
              this.UserStatus = true;
            }
          } else {
            this.UserStatus = true;
          }
        }
      });
    },
    SelectId(v) {
      Axios.get("/api/v1/select/SelectId/User?UserId=" + v).then(res => {
        if (res.status == 200) {
          this.User = res.data;
          this.UserName = res.data.UserName;
        }
      });
    },
    InsertUpdate() {
      if (this.$route.query.UserId != null) {
        //update
        Axios.put("/api/v1/update/update/user", this.User).then(res => {
          if (res.status == 200) {
            this.$router.push("/Employee");
          }
        });
      } else {
        //insert
        this.User.UserId = 0;
        Axios.post("/api/v1/insert/InsertEmp/User", this.User).then(res => {
          if (res.status == 200) {
            this.$router.push("/Employee");
          }
        });
      }
    }
  }
};
</script>
